<template>
  <NuxtLink
    class="no-underline"
    :to="{name: 'webshop-brand-product-slug', params: {brand: product.brand?.slug, slug: product.slug}, ...query}"
  >
    <div class="group flex w-full flex-col">
      <div class="flex items-center justify-center overflow-hidden">
        <img
          v-if="product.active_image"
          :src="product.active_image.url"
          :alt="product.title"
          class="w-auto transition duration-300 group-hover:scale-105"
        >
      </div>
      <div
        class="flex grow justify-between rounded-b-lg border border-t-0 border-gray-600 px-3 py-5 transition duration-300 group-hover:bg-gray-100"
      >
        <div class="mb-2 text-base font-normal">
          {{ product.title }}
        </div>
        <div class="font-quicksand text-lg font-bold">
          <span
            v-if="showCoins"
            class="text-secondary-800"
          >
            {{ product.entercoins || 0 }} {{ product.entercoins === 1 ? 'entercoin' : 'entercoins' }}
          </span>
          <UiCurrency
            v-else
            :value="product.price"
            class="font-semibold"
          />
        </div>
        <div class="flex self-end">
          <WebshopModal
            v-model:open="addedToCartModal"
            title="Het artikel is toegevoegd aan je winkelwagen"
            @update:model-value="updateValue"
          />
          <button
            class="btn btn-secondary right-0 flex"
            @click.prevent="addToCart"
          >
            <UiIcon name="shopping-cart-plus" />
          </button>
        </div>
      </div>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import {CartActionEnum, Product, useUpdateCartMutation} from '~/graphql/graphql';

const props = withDefaults(defineProps<{
  product: Product
  showCoins: boolean,
}>(), {
  showCoins: false,
});

const query = computed(() => {
  if (!props.showCoins) {
    return undefined;
  }

  return {query: {gift: true}};
});

const {items} = useCart();

const currentQuantity = computed(() => {
  const productFoundInCart = items.value.find((item) => {
    return item.product.slug === props.product.slug;
  });

  if (!productFoundInCart) {
    return 0;
  }

  return productFoundInCart.quantity;
});

const addedToCartModal = ref(false);

function addToCart() {
  const {mutate, onDone} = useUpdateCartMutation({
    variables: {
      quantity: currentQuantity.value ? currentQuantity.value + 1 : props.product.minimum_purchase ?? 1,
      product: props.product.slug,
      action: CartActionEnum.Update,
    },
    refetchQueries: ['cart'],
  });

  mutate();

  onDone(() => addedToCartModal.value = true);
}

function updateValue() {
  addedToCartModal.value = false;
}
</script>
